<template>
  <div>
    <div style="padding: 5px 0">
      <el-input
        v-model="search.meetingName"
        @keyup.enter.native="load"
        style="width: 200px; margin-right: 10px"
        placeholder="请输入会议名称"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-select v-model.number="search.status" clearable>
        <el-option
          v-for="item in statusInfo"
          :key="item.id"
          :label="item.statusName"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button
        @click="load"
        type="primary"
        size="mini"
        style="margin: 10px"
        icon="el-icon-search"
        >搜索</el-button>
      <el-button
        icon="el-icon-minus"
        type="danger"
        size="mini"
        style="margin-right: 10px"
        @click="batchDelete"
        :disabled="this.tableChecked.length === 0"
      >
        批量删除
      </el-button>

      <el-button 
      icon="el-icon-download"
      type="primary"
      size="mini"
      style="margin-right: 10px"
      @click="batchExport">
        批量导出
      </el-button>
      <el-button
        size="medium"
        class="el-icon-refresh"
        style="float: right; border: none"
        @click="load"
        >刷新数据</el-button
      >
    </div>
    <el-table
      :data="tableData"
      border
      stripe
      v-loading="loading"
      style="width: 100%"
      @selection-change="handleBatchDelete"
    >
    <el-table-column
        width="50"
        align="center"
        type="selection"
      ></el-table-column>
      <el-table-column
        label="序号"
        width="50"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <!-- <el-table-column prop="id" label="ID" width="100" sortable> </el-table-column> -->
      <el-table-column prop="meetingName" label="会议名称"></el-table-column>
      <el-table-column prop="account" label="报名人工号/学号">
      </el-table-column>
      <el-table-column prop="username" label="报名人姓名">
      </el-table-column>
      <el-table-column prop="phone" label="电话号码"></el-table-column>
      <el-table-column prop="satus" label="报名状态">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.status === 0"
            >取消报名</el-tag
          >
          <el-tag type="warning" v-if="scope.row.status === 1"
            >已报名</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="userId"
        label="负责人工号"
        
      ></el-table-column>

      <el-table-column prop="meetingName" label="会议名称">
      </el-table-column>

      <el-table-column prop="createTime" label="报名日期"></el-table-column>
      

      <el-table-column fixed="right" label="操作" width="185">
        <template slot-scope="scope">
          <!-- <el-button
            v-if="scope.row.meetingStatus === 1"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button> -->
          <!-- <el-button
            v-else-if="scope.row.meetingStatus === 4"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <el-button
            v-else-if="scope.row.meetingStatus === 5"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>

          <el-button
            v-else
            disabled
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button> -->
          <el-popconfirm @confirm="del(scope.row.id)" title="确定删除？">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              slot="reference"
              style="margin-left: 10px"
            ></el-button>
          </el-popconfirm>

          <el-button
            v-if="
              scope.row.status === 1
            "
            type="warning"
            round
            style="margin-left: 10px"
            @click="sumbitCheck(scope.row)"
            >取消报名</el-button
          >
         
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[2, 5, 10]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    
  </div>
</template>

<script>
import API from "../../utils/request";
const url = "/api/signup/";
const exportUrl = "http://localhost:9999/api/meeting/"

export default {
  name: "Meeting",
  data() {
    return {
      loading: false,
      tableChecked:[],
      text: "",
      user: {},
      tableData: [],
      pageNum: 1,
      pageSize: 5,
      total: 0,
      search: {
        meetingName: "",
        status: "",
      },
      statusInfo: [{id:0,statusName:"取消报名"},{id:1,statusName:"报名成功"}]
    };
  },
  computed: {
    
  },
  created() {
    this.user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.load();
  },
  methods: {
     handleBatchDelete(val) {
      // console.log("val",val)
      this.tableChecked = [];
      val.forEach((element) => {
        this.tableChecked.push(element.id);
        // console.log("tableChecked",this.tableChecked)
      });
    },
    batchDelete() {
        this.$confirm('此操作将永久删除会议信息，是否继续？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      var idsParams = {
        'ids': this.tableChecked,
      };
      API.post(url + "batchDelete", idsParams).then((res) => {
        if (res.code === "0") {
          this.load()
          this.$message({
          type: 'success',
          message: '删除成功!'
        });
        
        }
        if (res.code === "-1") {
          this.$message({
          type: 'error',
          message: '删除失败'
        });
        }
      });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    batchExport() {
      window.location.href=exportUrl + "exportMeetingInfo";
    },
    indexMethod(index) {
      return index + 1;
    },
    fileSuccessUpload(res) {
      this.entity.file = "http://localhost:9999/files/" + res.data;
      this.entity.img = "http://localhost:9999/files/" + res.data;
      this.fileList = [res.data];
      console.log("file.res", res);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.load();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load();
    },

    load() {
      API.post(
        url + "page/" + this.pageNum + "/" + this.pageSize + "/" + this.user.username,
        this.search
      ).then((res) => {
          console.log("res",res)
        this.tableData = res.data.records || [];
        this.total = res.data.total;
      });

    },
    sumbitCheck(checkInfo) {
      this.entity = JSON.parse(JSON.stringify(checkInfo));
      API.put(url + "/updateCheck", this.entity).then((res) => {
        if (res.code === "0") {
          this.$message({
            type: "success",
            message: "操作成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
        this.load();
      });
    },
    del(id) {
      API.delete(url + "/" + id).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.load();
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
        text-align: justify;
    }
</style>
